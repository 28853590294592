<template>
  <div id="sets-overall-report" class="container-fluid pb-5 position-relative">
    <div class="page-header mb-1">
      <strong>ดูของรวม/คาดคะเนได้-เสีย หวยชุด</strong>
    </div>
    <b-card no-body class="mb-1">
      <b-card-header header-tag="nav">
        <div class="d-flex justify-content-between mb-2">
          <h5 class="card-title mb-0">
            <i class="fas fa-info-circle text-danger mr-2"></i>
            <span class="text-dark mr-2">เฉพาะ</span>
            <span class="text-primary mr-2">{{ roundName }}</span>
            <span class="text-dark mr-2">วันที่</span>
            <span class="text-primary mr-2">{{ roundDate }}</span>
            <small class="text-secondary">(เปลี่ยนได้ที่แถบด้านบน)</small>
          </h5>
          <span>
            <button type="button" class="btn alert-warning border-warning btn-sm py-0" @click="getReport(false)">รีเฟรชในอีก {{countDown}} วินาที</button>
          </span>
        </div>

        <b-nav v-if="rates.length > 1" card-header tabs>
          <b-nav-item @click="toggleRate(null)" :active="rateId===null">ทั้งหมด</b-nav-item>
          <b-nav-item
            v-for="rate in rates"
            @click="toggleRate(rate._id)"
            :active="rateId===rate._id"
            :key="rate._id"
          >{{ rate.rateTitle }}</b-nav-item>
        </b-nav>
      </b-card-header>

      <div class="p-2">
        <b-input-group size="md" prepend="ค้นหาหมายเลข" class="mb-2">
          <b-form-input
            v-model="searchNumbers"
            v-on:keydown="keynumber"
            maxlength="20"
            placeholder="กรอกตัวเลข 2-4 ตัว"
          ></b-form-input>
          <b-input-group-append>
            <b-button variant="warning" @click="reset">รีเซ็ต</b-button>
          </b-input-group-append>
          <b-input-group-append>
            <b-button variant="primary" @click="search">ค้นหา</b-button>
          </b-input-group-append>
        </b-input-group>

        <b-input-group prepend="แสดงข้อมูล" size="md" class="mb-2">
          <b-input-group-append>
            <b-button :variant="dispData==='winlose'?'warning':'outline-warning'" @click="dispData='winlose'">ได้-เสีย</b-button>
            <b-button :variant="dispData==='holding'?'warning':'outline-warning'" @click="dispData='holding'">ถือหุ้น</b-button>
            <b-button :variant="dispData==='qty'?'warning':'outline-warning'" @click="dispData='qty'">จำนวนชุด</b-button>
            <!-- <b-button :variant="dispData==='bill'?'warning':'outline-warning'" @click="dispData='bill'">จำนวนโพย</b-button> -->
            <b-button :variant="dispData==='prize'?'warning':'outline-warning'" @click="dispData='prize'">จ่าย</b-button>
            <b-button :variant="dispData==='amount'?'warning':'outline-warning'" @click="dispData='amount'">ยอดทั้งหมด</b-button>
          </b-input-group-append>
        </b-input-group>

        <b-input-group prepend="เรียงลำดับ" size="md" class="mb-2">
          <b-input-group-prepend>
            <b-button :variant="sortBy==='winlose'?'warning':'outline-warning'" @click="sortBy='winlose';sortOrder='asc'">ได้-เสีย</b-button>
            <b-button :variant="sortBy==='number'?'warning':'outline-warning'" @click="sortBy='number';sortOrder='asc'">หมายเลข</b-button>
            <b-button :variant="sortBy==='qty'?'warning':'outline-warning'" @click="sortBy='qty';sortOrder='desc'">จำนวนชุด</b-button>
            <!-- <b-button :variant="sortBy==='bill'?'warning':'outline-warning'" @click="sortBy='bill';sortOrder='desc'">จำนวนโพย</b-button> -->
            <b-button :variant="sortBy==='received'?'warning':'outline-warning'" @click="sortBy='received';sortOrder='desc'">ถือหุ้น</b-button>
            <b-button :variant="sortBy==='amount'?'warning':'outline-warning'" @click="sortBy='amount';sortOrder='desc'">ยอดทั้งหมด</b-button>
          </b-input-group-prepend>
          <b-input-group-prepend is-text>
            เรียงจาก
          </b-input-group-prepend>
          <b-input-group-append>
            <b-button :variant="sortOrder==='asc'?'warning':'outline-warning'" @click="sortOrder='asc'">น้อย > มาก</b-button>
            <b-button :variant="sortOrder==='desc'?'warning':'outline-warning'" @click="sortOrder='desc'">มาก > น้อย</b-button>
          </b-input-group-append>
        </b-input-group>

        <b-input-group prepend="จำนวนแถว" size="md">
          <b-input-group-append>
            <b-button :variant="rowLength===10?'warning':'outline-warning'" @click="rowLength=10">10</b-button>
            <b-button :variant="rowLength===50?'warning':'outline-warning'" @click="rowLength=50">50</b-button>
            <b-button :variant="rowLength===100?'warning':'outline-warning'" @click="rowLength=100">100</b-button>
            <b-button :variant="rowLength===250?'warning':'outline-warning'" @click="rowLength=250">250</b-button>
            <b-button :variant="rowLength===500?'warning':'outline-warning'" @click="rowLength=500">500</b-button>
            <b-button :variant="rowLength===750?'warning':'outline-warning'" @click="rowLength=750">750</b-button>
            <b-button :variant="rowLength===1000?'warning':'outline-warning'" @click="rowLength=1000">ทั้งหมด</b-button>
          </b-input-group-append>
        </b-input-group>

        <div class="mt-4 mb-2">
          <small class="alert-primary rounded px-1 border-primary">พื้นหลังสีฟ้า = <strong>เต็มแล้ว</strong></small>
          <small class="alert-warning rounded px-1 border-warning ml-2">พื้นหลังสีเหลือง = <strong>ถูกรางวัล</strong></small>
          <small class="alert-secondary rounded px-1 border-secondary ml-2">กด Ctrl+F เพื่อค้นหา</small>
        </div>
        <table class="table table-pp table-sm table-bordered table-hover mt-2 mb-0">
          <thead>
            <tr>
              <th :width="colWidth">รวม</th>
              <th v-for="openBet in marketOpenBets" :key="openBet.code" :width="colWidth">{{ openBet.text }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th class="text-center">ยอดทั้งหมด</th>
              <th :colspan="marketOpenBets.length" class="text-right text-primary">{{ summary.totalAmount | amountFormat(2, '0.00') }}</th>
            </tr>
            <tr>
              <th class="text-center">ถือหุ้น</th>
              <th :colspan="marketOpenBets.length" class="text-right text-success">{{ summary.agentHolding | amountFormat(2, '0.00') }}</th>
            </tr>
            <tr>
              <th class="text-center">คอมฯ</th>
              <th :colspan="marketOpenBets.length" class="text-right" :class="[{'text-danger': summary.agentCommission<0}, {'text-success': summary.agentCommission>0}]">{{ summary.agentCommission | amountFormat(2, '0.00') }}</th>
            </tr>
            <tr>
              <th class="text-center">รับสูงสุด</th>
              <th v-for="openBet in marketOpenBets" class="text-right" :class="[{'text-danger': keySummary[openBet.code].win<0}, {'text-success': keySummary[openBet.code].win>0}]">{{ keySummary[openBet.code].win | amountFormat(2, '0.00') }}</th>
            </tr>
            <tr>
              <th class="text-center">จ่ายสูงสุด</th>
              <th v-for="openBet in marketOpenBets" class="text-right" :class="[{'text-danger': keySummary[openBet.code].lose<0}, {'text-secondary': keySummary[openBet.code].lose>=0}]">
                <span v-if="keySummary[openBet.code].lose < 0">{{ keySummary[openBet.code].lose | amountFormat(2, '0.00') }}</span>
                <span v-else>0.00</span>
              </th>
            </tr>
          </tbody>
          <tbody>
            <tr v-for="(item, index) in items" :key="item._id.rateId +'-'+ item._id.number">
              <td class="text-center">{{ index+1 }}</td>
              <td
                v-for="openBet in marketOpenBets"
                :class="[
                  {'alert-primary': isSoldOut(numberItems[openBet.code], index, openBet.code)},
                  {'alert-warning': isWon(numberItems[openBet.code], index, openBet.code)}
                ]"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <span class="text-primary click-report" @click="viewItem(numberItems[openBet.code], index, openBet.code)">{{ showBetNumber(numberItems[openBet.code], index) }}</span>
                  <span v-if="showBetNumber(numberItems[openBet.code], index)===''"></span>
                  <span v-else>
                    <span v-if="dispData==='winlose' || dispData==='prize'" :class="[{'text-danger': showData(numberItems[openBet.code], index)<0}, {'text-success': showData(numberItems[openBet.code], index)>0}]">{{ showData(numberItems[openBet.code], index) | amountFormat(2, '0.00') }}</span>
                    <span v-else-if="dispData==='holding' || dispData==='amount'" class="text-info">{{ showData(numberItems[openBet.code], index) | amountFormat(2, '0.00') }}</span>
                    <span v-else class="text-info">{{ showData(numberItems[openBet.code], index) | amountFormat(0, '0.00') }}</span>
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>

    <ViewSetsItemsModal
      :isShow="isShowModal"
      :roundId="roundId"
      :rateId="rateId"
      :itemPrize="itemPrize"
      :itemNumber="itemNumber"
      @close="closeModal"
    />

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />
  </div>
</template>
<script>
import { marketTypes } from "@/configs/market.config"
import SetsReportService from "@/services/SetsReportService"
import moment from '@/helpers/moment'
import cAlert from '@/helpers/alert'
import _ from 'lodash'
import SetsHelper from '@/helpers/sets'
import ViewSetsItemsModal from './components/ViewSetsItemsModal'

export default {
  name: 'SetsOverall',
  components: {
    ViewSetsItemsModal
  },
  data() {
    return {
      data: null,
      isLoading: false,
      refreshInterval: null,
      countDown: 299,
      searchNumbers: null,
      dispData: 'winlose',
      rowLength: 50,
      sortBy: 'winlose',
      sortOrder: 'asc',
      rateId: null,
      itemPrize: null,
      itemNumber: '',
      isShowModal: false
    }
  },
  computed: {
    round() {
      return this.$store.state.globalMarket
    },
    roundId() {
      return this.$store.state.globalMarket?.round?.roundId
    },
    roundName() {
      if(!this.data)
        return ''

      return `[${this.data?.round?.note?.groupTitle}] ${this.data?.round?.note?.marketTitle}`
    },
    roundDate() {
      if(!this.data)
        return ''

      return moment(this.data?.round?.roundDate?.date).format("DD/MM/YYYY")
    },
    market() {
      return this.data?.market ?? null
    },
    marketOpenBets() {
      if(!this.market)
        return []

      const marketType = marketTypes.find(t=>t.code===this.market.marketType)
      if(!marketType)
        return []

      const set = marketType.sets.find(s=>s.set===this.market.marketSet)
      if(!set)
        return []

      return (set?.openBets || []).filter((o)=>{
        return this.market?.openBets?.[o.code]
      })
    },
    rates() {
      return this.data?.rates ?? []
    },
    colWidth() {
      return `${100/(this.marketOpenBets.length+1)}%`
    },
    summary() {
      return (this.data?.summary ?? []).reduce((sum, item)=>{
        sum.totalAmount += item.totalAmount
        sum.agentHolding += item.agentHolding
        sum.agentCommission += item.agentCommission
        sum.agentTotal += item.agentTotal
        return sum
      }, {
        totalAmount: 0,
        agentHolding: 0,
        agentCommission: 0,
        agentTotal: 0
      })
    },
    listData() {
      return this.marketOpenBets.reduce((groups, bet)=>{
        let listData = []
        if(bet.code === 'threeNumberTode') {
          /**
           * เก็บเลขที่รวมแล้ว
           */
          const todeData = {}
          listData = (this.data.reports?.[bet.code] ?? []).map((item)=>{
            /**
             * จัดเรียงตัวเลขใหม่
             */
            const number = SetsHelper.setGroupNumber(item._id.number)

            if(todeData[number]) {
              /**
               * มีข้อมูลที่รวมไว้แล้ว
               */
              return {
                ...item,
                ...todeData[number]
              }
            }else{
              const todeNumbers = SetsHelper.gThreeNumberTode(number)
              todeData[number] = (this.data.reports?.[bet.code] ?? []).filter((it)=>{
                return todeNumbers.includes(it._id.number)
              })
              .reduce((total, it)=>{
                total.agentAmount += it.agentAmount
                total.agentCommission += it.agentCommission
                total.agentPrize += it.agentPrize
                total.agentReward += it.agentReward
                total.agentTotal += it.agentTotal
                total.amount += it.amount
                total.count += it.count
                total.qty += it.qty
                return total
              }, {
                agentAmount: 0,
                agentCommission: 0,
                agentPrize: 0,
                agentReward: 0,
                agentTotal: 0,
                amount: 0,
                count: 0,
                qty: 0
              })
              return {
                ...item,
                ...todeData[number]
              }
            }
          })
        }else
        if(bet.code === 'fourNumberTode') {
          /**
           * เก็บเลขที่รวมแล้ว
           */
          const todeData = {}
          listData = (this.data.reports?.[bet.code] ?? []).map((item)=>{
            /**
             * จัดเรียงตัวเลขใหม่
             */
            const number = SetsHelper.setGroupNumber(item._id.number)

            if(todeData[number]) {
              /**
               * มีข้อมูลที่รวมไว้แล้ว
               */
              return {
                ...item,
                ...todeData[number]
              }
            }else{
              const todeNumbers = SetsHelper.gFourNumberTode(number)
              todeData[number] = (this.data.reports?.[bet.code] ?? []).filter((it)=>{
                return todeNumbers.includes(it._id.number)
              })
              .reduce((total, it)=>{
                total.agentAmount += it.agentAmount
                total.agentCommission += it.agentCommission
                total.agentPrize += it.agentPrize
                total.agentReward += it.agentReward
                total.agentTotal += it.agentTotal
                total.amount += it.amount
                total.count += it.count
                total.qty += it.qty
                return total
              }, {
                agentAmount: 0,
                agentCommission: 0,
                agentPrize: 0,
                agentReward: 0,
                agentTotal: 0,
                amount: 0,
                count: 0,
                qty: 0
              })
              return {
                ...item,
                ...todeData[number]
              }
            }
          })
        }else{
          listData = (this.data.reports?.[bet.code] ?? [])
        }
        groups[bet.code] = listData
        return groups
      }, {})
    },
    numberItems() {
      return this.marketOpenBets.reduce((groups, bet)=>{
        groups[bet.code] = _.chunk(_.sortBy(this.listData[bet.code], (item)=>{
          return {
            winlose: this.sortOrder==='asc' ? item.agentPrize : -item.agentPrize,
            number: this.sortOrder==='asc' ? parseInt(item._id.number) : -parseInt(item._id.number),
            qty: this.sortOrder==='asc' ? item.qty : -item.qty,
            bill: this.sortOrder==='asc' ? item.count : -item.count,
            received: this.sortOrder==='asc' ? item.agentAmount : -item.agentAmount,
            amount: this.sortOrder==='asc' ? item.amount : -item.amount
          }[this.sortBy]
        }), this.rowLength)[0]
        return groups
      }, {})
    },
    maxRow() {
      return _.maxBy(this.marketOpenBets.map((bet)=>{
        return {
          key: bet.code,
          length: this.numberItems?.[bet.code]?.length ?? 0
        }
      }), (item)=>{
        return item.length
      })
    },
    items() {
      return this.numberItems?.[this.maxRow?.key] ?? []
    },
    keySummary() {
      return this.marketOpenBets.reduce((sums, openBet)=>{

        const allItems = (this.listData?.[openBet.code] ?? [])
        const forecast = {
          win: 0,
          lose: 0
        }

        if({
          'fiveNumber': allItems.length < 100000,
          'fourNumber': allItems.length < 10000,
          'fourNumberTode': allItems.length < 10000,
          'threeNumber': allItems.length < 1000,
          'threeNumberTode': allItems.length < 1000,
          'twoNumberPrefix': allItems.length < 100,
          'twoNumberSuffix': allItems.length < 100
        }[openBet.code]) {
          forecast['win'] = this.summary.agentTotal
        }else{
          /**
           * จ่ายน้อยสุด
           * ค่าเป็นลบต้องใช้ maxBy
           */
          const min = _.maxBy(allItems, (item)=>{
            return item.agentPrize
          })
          forecast['win'] = this.summary.agentTotal + (min?.agentPrize ?? 0)
        }

        /**
         * จ่ายเยอะสุด
         * ค่าเป็นลบต้องใช้ minBy
         */
        const max = _.minBy(allItems, (item)=>{
          return item.agentPrize
        })
        forecast['lose'] = this.summary.agentTotal + (max?.agentPrize ?? 0)

        sums[openBet.code] = forecast

        return sums
      }, {})
    },
    settings() {
      return Object.keys(this.data?.settings ?? {}).map((rateId)=>{
        return {
          ...this.data.settings[rateId],
          rateId: rateId
        }
      })
    },
    setReceive() {
      return this.settings.filter((rate)=>{
        return !this.rateId || rate.rateId === this.rateId
      })
      .reduce((setWithKeys, rate)=>{

        return this.marketOpenBets.reduce((setWithKeys2, openBet)=>{

          /**
           * รับของรวม
           */
          if(!setWithKeys2?.[openBet.code]) {
            setWithKeys2[openBet.code] = {
              numbers: {},
              default: rate?.receives?.setReceiveAmount ?? 0
            }
          }else{
            setWithKeys2[openBet.code].default += (rate?.receives?.setReceiveAmount ?? 0)
          }

          /**
           * รับของรายตัว
           */
          const rateNumbers = rate?.numbers?.[openBet.code] ?? {}
          /**
           * รวมกับรับของรายตัว rate อื่นๆ
           */
          const ratesNumbers = {
            ...setWithKeys2[openBet.code].numbers,
            ...rateNumbers
          }

          if(Object.keys(ratesNumbers).length > 0) {
            setWithKeys2[openBet.code].numbers = Object.keys(ratesNumbers)
            .reduce((numbers, number)=>{
              const nAmount = rateNumbers?.[number] ?? (rate?.receives?.setReceiveAmount ?? 0)
              if(numbers?.[number]) {
                numbers[number] += nAmount
              }else{
                numbers[number] = nAmount
              }

              return numbers
            }, setWithKeys2[openBet.code].numbers)
          }

          return setWithKeys2
        }, setWithKeys)


      }, {})
    }
  },
  watch: {
    roundId(n, o) {
      if(this.roundId) {
        this.rateId = null
        this.searchNumbers = null
        this.getReport()
      }
    }
  },
  methods: {
    getReport(silent=false) {
      /**
       * ตรวจสอบประเภทหวย
       */
      if(this.$store.state.globalMarket?.marketType==='single') {
        return this.$router.push({name: 'RoundOverall'})
      }

      if(!silent)
        this.isLoading = true

      clearInterval(this.refreshInterval)
      SetsReportService.getRoundOverall(this.roundId, {
        rateId: this.rateId,
        numbers: this.searchNumbers ? this.searchNumbers.split(',') : null
      })
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response.data)
        }
      })
      .catch((e)=>{
        cAlert({
          ...e,
          title: 'ผิดพลาด!',
          text: e?.message || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
        this.countDownRefresh()
      })
    },
    toggleRate(rateId) {
      this.rateId = rateId
      this.getReport()
    },
    countDownRefresh() {
      this.countDown = 299
      clearInterval(this.refreshInterval)
      this.refreshInterval = setInterval(()=>{
        this.countDown--
        if(this.countDown < 1) {
          this.getReport(true)
        }
      }, 1000)
    },
    showBetNumber(items, index) {
      return items?.[index]?._id?.number ?? ''
    },
    showData(items, index, def='') {

      let winlose = null
      if(this.dispData === 'winlose' && items?.[index]?.['agentPrize']) {
        winlose = this.summary.agentTotal + (items?.[index]?.['agentPrize'] || 0)
      }

      return {
        'winlose': winlose ?? def,
        'holding': items?.[index]?.['agentAmount'] ?? def,
        'qty': items?.[index]?.['qty'] ?? def,
        'bill': items?.[index]?.['count'] ?? def,
        'prize': items?.[index]?.['agentPrize'] ?? def,
        'amount': items?.[index]?.['amount'] ?? def
      }?.[this.dispData] ?? def
    },
    isWon(items, index, betKey) {
      return (this.data?.round?.results?.[betKey] || []).includes(this.showBetNumber(items, index))
    },
    isSoldOut(items, index, betKey) {
      const number = items?.[index]?._id?.number ?? null
      if(!number)
        return false

      const qty = items?.[index]?.['qty'] ?? 0
      /**
       * ตรวจสอบจากการตั้งค่าเลข
       */
      if(this.setReceive?.[betKey]?.numbers?.[number])
        return qty >= this.setReceive?.[betKey]?.numbers?.[number]

      const setDefault = this.setReceive?.[betKey]?.default ?? 0
      return qty >= setDefault
    },
    viewItem(items, index, betKey) {
      const number = items?.[index]?._id?.number ?? null
      if(!number)
        return false

      this.itemPrize = betKey
      this.itemNumber = number
      this.isShowModal = true
    },
    closeModal() {
      this.itemPrize = null
      this.itemNumber = ''
      this.isShowModal = false
    },
    reset() {
      this.searchNumbers = null
      this.getReport()
    },
    search() {
      if(this.searchNumbers) {
        this.getReport()
      }
    },
    keynumber(event) {
      if(!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', ',', 'Backspace'].includes(event.key)) {
        event.preventDefault()
      }
    }
  },
  mounted() {
    this.getReport()
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval)
  }
}
</script>
<style lang="scss" scoped>
#sets-overall-report {
  .table {
    font-size: 90%;

    thead {
      tr {
        th, td {
          text-align: center;
          vertical-align: middle;
        }
      }
    }
    tbody {
      background-color: #FFF;
    }

    th, td {
      vertical-align: middle;

      &.border-right {
        border-right: 3px solid #86cfda !important;
      }
      &.border-top {
        border-top: 3px solid #86cfda !important;
      }

      .click-report {
        cursor: pointer;
      }

      .click-report:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
