<template>
  <b-modal
    id="sets-items-modal"
    ref="sets-items-modal"
    no-close-on-backdrop
    hide-footer
    @show="setData"
    size="xl"
    dialog-class="items-modal position-relative"
    body-class="p-0"
    header-class="align-items-center p-2"
  >
    <template #modal-header="{ close }">
      <div class="d-flex">
        <h5 class="mb-0">รางวัล <span class="text-danger">{{itemPrize | prizeText}}</span> หมายเลข <span class="text-primary">{{itemNumber}}</span></h5>
      </div>
      <b-button size="sm" variant="outline-danger" @click="close()">
        <i class="fas fa-times"></i>
      </b-button>
    </template>

    <div class="items-table">

      <div v-if="totalDoc > perPage" class="pt-0">
        <ol class="breadcrumb pl-2 pr-0 py-0 m-0 mb-0 border-info rounded-0 d-flex justify-content-between align-items-center">
          <li class="breadcrumb-item py-1">
            <span>แสดง {{ startRow | amountFormat(0) }} - {{ endRow | amountFormat(0) }} จาก {{ totalDoc | amountFormat(0) }} รายการ</span>
          </li>
          <li>
            <ul v-if="pages.length > 1" class="pagination justify-content-end mb-0">
              <li v-if="currentPage > 1" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(currentPage - 1)"><i class="fas fa-angle-left"></i></a>
              </li>
              <li v-if="currentPage > 3" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(1)">1</a>
              </li>
              <li v-if="currentPage > 4 && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>

              <li
                v-for="(page, index) in pages"
                :key="index"
                class="page-item"
                :class="[{'active': page === currentPage}]"
                style="cursor: pointer; border-radius: 0;"
              ><a class="page-link rounded-0" @click="togglePage(page)">{{page}}</a></li>

              <li v-if="currentPage < (totalPage - 3) && totalPage > 6" class="d-flex align-items-center p-1"><i class="fas fa-ellipsis-h"></i></li>
              <li v-if="currentPage < (totalPage - 2)" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(totalPage)">{{totalPage}}</a>
              </li>
              <li v-if="currentPage < totalPage" class="page-item" style="cursor: pointer; border-radius: 0;">
                <a class="page-link rounded-0" @click="togglePage(currentPage + 1)"><i class="fas fa-angle-right"></i></a>
              </li>
            </ul>
          </li>
        </ol>
      </div>

      <table class="table table-sm table-bordered mb-0 table-pp">
        <thead>
          <tr>
            <th width="60" rowspan="2" class="text-center">#</th>
            <th width="150" rowspan="2" class="text-center">
              ชื่อใช้งาน
              <b-form-checkbox
                v-model="showName"
                :value="true"
                :unchecked-value="false"
                class="mt-2 font-weight-normal"
              >
                แสดงชื่อ
              </b-form-checkbox>
            </th>
            <th width="150" rowspan="2" class="text-center">วันที่</th>
            <!-- <th width="120" rowspan="2" class="text-center">ประเภท</th> -->
            <th width="100" rowspan="2" class="text-center">หมายเลข</th>
            <th width="80" rowspan="2" class="text-center">ชุด</th>
            <th width="100" rowspan="2" class="text-center">จำนวน</th>
            <th colspan="3" class="text-center border-left border-right">เมมเบอร์</th>
            <th colspan="3" class="text-center border-right">{{ agentUsername }}</th>
            <th colspan="3" class="text-center border-right">บริษัท</th>
            <th rowspan="2" class="text-center">หมายเหตุ</th>
          </tr>
          <tr>
            <th width="100" class="text-center border-left">ส่งออก</th>
            <th width="100" class="text-center">คอมฯ</th>
            <th width="100" class="text-center border-right">สุทธิ</th>

            <th width="100" class="text-center">ถือหุ้น</th>
            <th width="100" class="text-center">คอมฯ</th>
            <th width="100" class="text-center border-right">รวม</th>

            <th width="100" class="text-center">ถือหุ้น</th>
            <th width="100" class="text-center">คอมฯ</th>
            <th width="100" class="text-center border-right">รวม</th>
          </tr>
          <tr v-if="items.length > 20">
            <th colspan="4" class="text-center">รวม {{ totalDoc | amountFormat(0) }} รายการ</th>
            <th class="text-center text-info">{{ summary.qty | amountFormat(0) }}</th>
            <th class="text-right text-info">{{ summary.amount | amountFormat }}</th>
            <th
              class="text-right border-left"
              :class="[{
                'text-success': summary.memberAmount>0
              }, {
                'text-danger': summary.memberAmount<0
              }]"
            >{{ summary.memberAmount | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': summary.memberCommission>0
              }, {
                'text-danger': summary.memberCommission<0
              }]"
            >{{ summary.memberCommission | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': summary.memberTotal>0
              }, {
                'text-danger': summary.memberTotal<0
              }]"
            >{{ summary.memberTotal | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': summary.agentAmount>0
              }, {
                'text-danger': summary.agentAmount<0
              }]"
            >{{ summary.agentAmount | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': summary.agentCommission>0
              }, {
                'text-danger': summary.agentCommission<0
              }]"
            >{{ summary.agentCommission | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': summary.agentTotal>0
              }, {
                'text-danger': summary.agentTotal<0
              }]"
            >{{ summary.agentTotal | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': summary.companyAmount>0
              }, {
                'text-danger': summary.companyAmount<0
              }]"
            >{{ summary.companyAmount | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': summary.companyCommission>0
              }, {
                'text-danger': summary.companyCommission<0
              }]"
            >{{ summary.companyCommission | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': summary.companyTotal>0
              }, {
                'text-danger': summary.companyTotal<0
              }]"
            >{{ summary.companyTotal | amountFormat(2, '0.00') }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item._id">
            <td class="text-center">{{offset+index+1}}</td>
            <td class="text-center">
              {{ item.account.mainUser.username }}
              <small v-if="showName" class="text-secondary">({{ item.account.mainUser.firstName }})</small>
            </td>
            <td class="text-center">{{ item.createdAt | dispDateTime('DD/MM/YY HH:mm') }}</td>
            <!-- <td class="text-center">{{ item.text }}</td> -->
            <td class="text-center text-primary">{{ item.number }}</td>
            <td class="text-center text-info">{{ item.qty | amountFormat(0) }}</td>
            <td class="text-right text-info">{{ item.amount | amountFormat }}</td>

            <td
              class="text-right border-left"
              :class="[{
                'text-success': item.refAmount.member.amount>0
              }, {
                'text-danger': item.refAmount.member.amount<0
              }]"
            >{{ item.refAmount.member.amount | amountFormat }}</td>
            <td
              class="text-right"
              :class="[{
                'text-success': item.refAmount.member.commission>0
              }, {
                'text-danger': item.refAmount.member.commission<0
              }]"
            >{{ item.refAmount.member.commission | amountFormat }}</td>
            <td
              class="text-right border-right"
              :class="[{
                'text-success': item.refAmount.member.total>0
              }, {
                'text-danger': item.refAmount.member.total<0
              }]"
            >{{ item.refAmount.member.total | amountFormat }}</td>

            <td
              class="text-right"
              :class="[{
                'text-success': item.refAmount.agent.amount>0
              }, {
                'text-danger': item.refAmount.agent.amount<0
              }]"
            >{{ item.refAmount.agent.amount | amountFormat }}</td>
            <td
              class="text-right"
              :class="[{
                'text-success': item.refAmount.agent.commission>0
              }, {
                'text-danger': item.refAmount.agent.commission<0
              }]"
            >{{ item.refAmount.agent.commission | amountFormat }}</td>
            <td
              class="text-right border-right"
              :class="[{
                'text-success': item.refAmount.agent.total>0
              }, {
                'text-danger': item.refAmount.agent.total<0
              }]"
            >{{ item.refAmount.agent.total | amountFormat }}</td>

            <td
              class="text-right"
              :class="[{
                'text-success': item.refAmount.company.amount>0
              }, {
                'text-danger': item.refAmount.company.amount<0
              }]"
            >{{ item.refAmount.company.amount | amountFormat }}</td>
            <td
              class="text-right"
              :class="[{
                'text-success': item.refAmount.company.commission>0
              }, {
                'text-danger': item.refAmount.company.commission<0
              }]"
            >{{ item.refAmount.company.commission | amountFormat }}</td>
            <td
              class="text-right border-right"
              :class="[{
                'text-success': item.refAmount.company.total>0
              }, {
                'text-danger': item.refAmount.company.total<0
              }]"
            >{{ item.refAmount.company.total | amountFormat }}</td>

            <td class="text-center">{{ item.remarks }}</td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="16" class="text-center">ไม่มีข้อมูลรับของ</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length">
          <tr>
            <th colspan="4" class="text-center">รวม {{ totalDoc | amountFormat(0) }} รายการ</th>
            <th class="text-center text-info">{{ summary.qty | amountFormat(0) }}</th>
            <th class="text-right text-info">{{ summary.amount | amountFormat }}</th>
            <th
              class="text-right border-left"
              :class="[{
                'text-success': summary.memberAmount>0
              }, {
                'text-danger': summary.memberAmount<0
              }]"
            >{{ summary.memberAmount | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': summary.memberCommission>0
              }, {
                'text-danger': summary.memberCommission<0
              }]"
            >{{ summary.memberCommission | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': summary.memberTotal>0
              }, {
                'text-danger': summary.memberTotal<0
              }]"
            >{{ summary.memberTotal | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': summary.agentAmount>0
              }, {
                'text-danger': summary.agentAmount<0
              }]"
            >{{ summary.agentAmount | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': summary.agentCommission>0
              }, {
                'text-danger': summary.agentCommission<0
              }]"
            >{{ summary.agentCommission | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': summary.agentTotal>0
              }, {
                'text-danger': summary.agentTotal<0
              }]"
            >{{ summary.agentTotal | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': summary.companyAmount>0
              }, {
                'text-danger': summary.companyAmount<0
              }]"
            >{{ summary.companyAmount | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right"
              :class="[{
                'text-success': summary.companyCommission>0
              }, {
                'text-danger': summary.companyCommission<0
              }]"
            >{{ summary.companyCommission | amountFormat(2, '0.00') }}</th>
            <th
              class="text-right border-right"
              :class="[{
                'text-success': summary.companyTotal>0
              }, {
                'text-danger': summary.companyTotal<0
              }]"
            >{{ summary.companyTotal | amountFormat(2, '0.00') }}</th>
            <th></th>
          </tr>
        </tfoot>
      </table>

      <loading
        :active="isLoading"
        :can-cancel="false"
        :is-full-page="false"
        background-color="#EBEDEF"
        :height="30"
        :width="30"
        color="#007bff"
      />
    </div>
  </b-modal>
</template>
<script>
import SetsReportService from "@/services/SetsReportService"
import cAlert from '@/helpers/alert'
import { openBetLabels } from '@/configs/market.config'
import SetsHelper from '@/helpers/sets'

export default {
  name: 'ViewSetsItemsModal',
  props: ['isShow', 'roundId', 'rateId', 'itemPrize', 'itemNumber'],
  data() {
    return {
      isLoading: false,
      showName: false,
      data: null,
      perPage: 100,
      currentPage: 1
    }
  },
  computed: {
    agentUsername() {
      return this.data?.account?.username ?? ''
    },
    items() {
      const levelId = this.data?.account?.levelId ?? ''
      return (this.data?.items ?? []).map((item)=>{
        return {
          ...item,
          refAmount: item.refsAmount[levelId]
        }
      })
    },
    summary() {
      return (this.data?.summary ?? []).reduce((sum, item)=>{
        sum.qty += item.qty
        sum.amount += item.amount
        sum.memberAmount += item.memberAmount
        sum.memberCommission += item.memberCommission
        sum.memberTotal += item.memberTotal
        sum.agentAmount += item.agentAmount
        sum.agentCommission += item.agentCommission
        sum.agentTotal += item.agentTotal
        sum.companyAmount += item.companyAmount
        sum.companyCommission += item.companyCommission
        sum.companyTotal += item.companyTotal
        sum.totalDoc += item.totalDoc
        return sum
      }, {
        qty: 0,
        amount: 0,
        memberAmount: 0,
        memberCommission: 0,
        memberTotal: 0,
        agentAmount: 0,
        agentCommission: 0,
        agentTotal: 0,
        companyAmount: 0,
        companyCommission: 0,
        companyTotal: 0,
        totalDoc: 0
      })
    },
    totalDoc() {
      return this.summary.totalDoc
    },
    totalPage() {
      return Math.ceil(this.totalDoc/this.perPage)
    },
    pages() {
      let prevPage = this.currentPage - 2
      let nextPage = this.currentPage + 2

      if(prevPage < 1) {
        nextPage = nextPage - prevPage + 1
      }else
      if(nextPage >= this.totalPage) {
        prevPage = prevPage - (nextPage - this.totalPage)
      }

      const startPage = prevPage < 1 ? 1 : prevPage
      const endPage = nextPage > this.totalPage ? this.totalPage : nextPage

      const pages = []
      for(let i=startPage; i<=endPage; i++) {
        pages.push(i)
      }

      return pages
    },
    offset() {
      return (this.currentPage * this.perPage) - this.perPage
    },
    startRow() {
      return this.offset + 1
    },
    endRow() {
      const max = this.startRow + this.perPage - 1
      return max < this.totalDoc ? max : this.totalDoc
    }
  },
  watch: {
    isShow() {
      if(this.isShow){
        this.$refs['sets-items-modal'].show()
      }else{
        this.$refs['sets-items-modal'].hide()
      }
    }
  },
  methods: {
    loadItems() {
      if(!this.roundId)
        return

      this.isLoading = true
      let numbers = [this.itemNumber]
      if(this.itemPrize === 'threeNumberTode') {
        numbers = SetsHelper.gThreeNumberTode(this.itemNumber)
      }else
      if(this.itemPrize === 'fourNumberTode') {
        numbers = SetsHelper.gFourNumberTode(this.itemNumber)
      }

      SetsReportService.getItems(this.roundId, {
        limit: this.perPage,
        rateId: this.rateId,
        type: this.itemPrize,
        numbers: numbers
      }, this.offset)
      .then((response)=>{
        if(response.success) {
          this.data = response.data
        }else{
          throw new Error(response?.message)
        }
      })
      .catch((e)=>{
        this.$refs['sets-items-modal'].hide()
        cAlert({
          title: 'ผิดพลาด!',
          text: 'ดึงรายการไม่สำเร็จ',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    setData() {
      this.currentPage = 1
      this.loadItems()
    },
    togglePage(page) {
      this.currentPage = page
      this.loadItems()
    }
  },
  filters: {
    prizeText(type) {
      return openBetLabels?.[type] ?? type
    }
  },
  mounted() {
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$emit('close')
    })
  }
}
</script>
<style lang="scss">
#sets-items-modal {
  .items-modal {
    max-width: 90% !important;
  }
  .items-table {
    min-height: 80px;

    .breadcrumb {
      font-size: 90%;
      border-left: 0 !important;
      border-right: 0 !important;

      .pagination {
        .page-item {
          .page-link {
            padding: 5px 10px;
          }
        }
      }
    }

    .border-right {
      border-right: 2px solid #ffc107 !important;
    }

    .border-left {
      border-left: 2px solid #ffc107 !important;
    }

    table {
      thead {
        tr {
          th {
            font-size: 85%;
            line-height: 1.1;
          }
        }
      }
      tbody {
        background-color: #FFF;

        tr {
          td {
            vertical-align: middle;
            padding: 4px;
            font-size: 90%;
          }
        }
      }
      tfoot {
        tr {
          th, td {
            font-size: 90%;
          }
        }
      }
    }
  }
}
</style>
